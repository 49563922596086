<template>
	<div id="staffDetail">
		<v-row v-if="staff" style="margin: 10px">
			<v-col style="max-width: 1400px" class="mx-auto">
				<h1>Detalhes</h1>
				<br />
				<UserDetails :user="staff" :onClose="closeStaffForm" />
			</v-col>
		</v-row>
		<v-btn v-if="features.editStaff" fab fixed bottom right color="primary" @click="openStaffForm(null)">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>
		<v-dialog v-model="showStaffForm" max-width="1400px">
			<StaffForm :staff="staff" :onClose="closeStaffForm" />
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import UserDetails from "../components/UserDetails";
import StaffForm from "../components/StaffForm";

import { getUser } from "../api/users";

export default Vue.extend({
	name: "StaffDetail",
	components: {
		UserDetails,
		StaffForm,
	},
	created() {
		this.handleGetStaff();
	},
	watch: {
		"$route.params.id": function () {
			this.handleGetStaff();
		},
	},
	computed: mapState(["features"]),
	data() {
		return {
			showStaffForm: false,

			staff: null,
		};
	},
	methods: {
		async handleGetStaff() {
			const response = await getUser(this.$route.params.id);

			if (response.status === 200) {
				this.staff = response.data;
			} else {
				this.$router.replace("/users");
			}
		},
		openStaffForm() {
			this.showStaffForm = true;
		},
		closeStaffForm() {
			this.handleGetStaff();

			this.showStaffForm = false;
		},
	},
});
</script>
