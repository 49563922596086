<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form
			ref="form"
			@submit.prevent="handleSubmit"
			style="display: flex; gap: 10px; justify-content: center; padding: 10px"
		>
			<div style="display: flex; gap: 15px; flex-direction: column; align-items: center; min-width: 600px">
				<div style="display: flex; gap: 10px; align-items: flex-end; width: 100%">
					<v-text-field outlined v-model="name" label="Nome" hide-details />
					<v-checkbox v-model="active" hint="Ativo" persistent-hint />
				</div>
				<div
					style="
						height: 900px;
						overflow-y: auto;
						display: flex;
						gap: 10px;
						flex-direction: column;
						align-items: center;
					"
				>
					<div
						v-for="(option, index) in options"
						:key="index"
						:style="`
						display: flex;
						gap: 10px;
						flex-direction: column;
						background-color: ${$vuetify.theme.dark ? '#333' : '#f2f2f2'};
						border-radius: 5px;
						padding: 10px;
					`"
					>
						<div style="display: flex; justify-content: space-between">
							<v-card-text style="font-size: 1.5em; text-align: left; padding: 5px">
								{{ fields.find(f => f.key === option.key)?.name || `Opção ${index + 1}` }}
							</v-card-text>
							<v-btn icon color="error" @click="() => deleteOption(index)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</div>
						<div style="display: flex; gap: 10px">
							<v-select
								outlined
								item-value="key"
								item-text="name"
								label="Campo"
								v-model="option.key"
								:items="fields"
								hide-details
								return-object
								@change="value => handleFieldChange(option, value)"
							/>
							<v-text-field outlined v-model="option.x" label="X" type="number" hide-details />
							<v-text-field outlined v-model="option.y" label="Y" type="number" hide-details />
						</div>
						<div v-if="option.type === 'text'" style="display: flex; gap: 10px; align-items: center">
							<v-select
								outlined
								item-value="key"
								item-text="name"
								label="Fonte"
								v-model="option.font"
								:items="fonts"
								hide-details
							/>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon @click="chooseFiles">
										<input id="fileUpload" type="file" hidden multiple @change="handleUploadFonts" />
										<v-icon>mdi-upload</v-icon>
									</v-btn>
								</template>
								<span>Adicionar Fonte</span>
							</v-tooltip>
							<v-text-field outlined v-model="option.fontSize" label="Tamanho" type="number" hide-details />
							<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-bind="attrs"
										v-on="on"
										outlined
										v-model="option.fontColor"
										label="Côr"
										hide-details
									/>
								</template>
								<v-color-picker v-model="option.fontColor" />
							</v-menu>
							<v-textarea
								v-if="option.key === 'extraText'"
								outlined
								v-model="option.extraText"
								label="Texto Extra"
								hide-details
								rows="1"
							/>
						</div>
						<v-text-field
							v-if="option.type === 'image'"
							outlined
							v-model="option.width"
							label="Largura"
							hide-details
						/>
					</div>
					<v-btn fab small color="primary" @click="() => addOption()">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<v-btn :disabled="loading" type="submit" color="primary">
					{{ template ? "Atualizar" : "Adicionar" }}
				</v-btn>
			</div>
			<div style="width: 100%; max-width: 800px">
				<div v-if="template && template.image">
					<div style="display: flex; gap: 10px; align-items: center; margin: 10px 0px">
						<v-autocomplete
							outlined
							item-value="_id"
							item-text="name"
							label="Exemplo"
							v-model="consultant"
							:items="users"
							hide-details
						/>
						<v-progress-circular v-if="loading" indeterminate color="primary" />
						<v-btn v-else :disabled="!consultant || loading" color="primary" @click="handleGeneratePreview">
							Gerar
						</v-btn>
					</div>
					<img :src="preview || getFileUrl(template.image)" width="100%" />
				</div>
				<VueFileAgent
					v-else
					accept="image/*"
					helpText="Escolha uma imagem ou faça drag & drop"
					:errorText="{ type: 'Tipo de ficheiro inválido. Só são aceites imagens' }"
					v-model="image"
					class="imageFile"
				/>
			</div>
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { getFonts, addTemplate, editTemplate, generateConsultantImages } from "../api/templates";
import { addFiles } from "../api/files";

import { getFileUrl } from "../utils/utils";

export default Vue.extend({
	name: "TemplateForm",
	props: ["template", "onClose"],
	async created() {
		if (this.template) this.setTemplate(this.template);

		this.handleGetFonts();
		this.handleGetUsers();
	},
	watch: {
		template: function (newVal) {
			this.setTemplate(newVal);
		},
	},
	computed: {
		...mapState(["company", "token", "user"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		return {
			loading: false,

			users: [],
			consultant: null,
			preview: null,

			fields: [
				{ key: "extraText", type: "text", name: "Texto Extra" },
				{ key: "image", type: "image", name: "Foto" },
				{ key: "team", type: "text", name: "Equipa" },
				{ key: "name", type: "text", name: "Nome" },
				{ key: "completeName", type: "text", name: "Nome Completo" },
				{ key: "role", type: "text", name: "Cargo" },
				{ key: "phone", type: "text", name: "Telefone" },
				{ key: "phone2", type: "text", name: "Telefone2" },
				{ key: "email", type: "text", name: "Email" },
				{ key: "officeName", type: "text", name: "Nome da Agência" },
				{ key: "officeFullName", type: "text", name: "Nome Completo da Agência" },
				{ key: "officeUrl", type: "text", name: "URL da Agência" },
				{ key: "officeAddress", type: "text", name: "Morada da Agência" },
				{ key: "officePostalCode", type: "text", name: "Código Postal da Agência" },
				{ key: "officePhone", type: "text", name: "Telefone da Agência" },
			],
			fonts: [],

			active: true,
			name: "",
			image: null,
			options: [{ key: "", x: 0, y: 0, font: "", fontSize: "", fontColor: "" }],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		getFileUrl,
		setTemplate(newVal) {
			this.consultant = null;
			this.preview = null;

			if (newVal) {
				this.active = newVal.active;
				this.name = newVal.name;
				this.options = newVal.options;
			} else {
				this.active = true;
				this.name = "";
				this.image = null;
				this.options = [];
			}
		},
		addOption() {
			this.options.push({ key: "", name: "", type: "", x: 0, y: 0, font: "", fontSize: "", fontColor: "" });
		},
		deleteOption(index) {
			this.options.splice(index, 1);
		},
		handleFieldChange(option, field) {
			option.key = field.key;
			option.type = field.type;
		},
		async handleGetFonts() {
			const response = await getFonts();

			if (response.status === 200) {
				this.fonts = response.data;
			}
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		async handleGeneratePreview() {
			this.loading = true;

			const { template, consultant } = this;

			await this.handleSubmit(true);

			await generateConsultantImages({ template, consultants: [consultant], isPreview: true });

			this.preview = `${this.apiUrl}/api/files/download/previews/${this.user._id}.jpg?${Date.now()}`;

			this.loading = false;
		},
		chooseFiles() {
			document.getElementById("fileUpload").click();
		},
		async handleUploadFonts(e) {
			this.loading = true;

			const formData = new FormData();
			const fileTypes = [];
			const fileNames = [];

			for (const file of e.target.files) {
				formData.append("files", file);
				fileTypes.push(`.${file.name.split(".")[1]}`);
				fileNames.push(`/${file.name}`);
			}

			formData.append("fileTypes", fileTypes);
			formData.append("fileNames", fileNames);

			await addFiles("templates", "fonts", "templates", formData, "font");

			await this.handleGetFonts();

			this.loading = false;
		},
		async uploadImage(id) {
			if (this.image) {
				const formData = new FormData();

				formData.append("files", this.image.file);
				formData.append("fileTypes", [this.image.ext]);
				formData.append("fileNames", [`/${this.image.file.name}`]);

				await addFiles("templates", id, "templates", formData, "profileImage");
			}

			this.image = null;
		},
		async handleSubmit(isPreview) {
			if (!isPreview) this.loading = true;

			const { active, name, options } = this;

			let response = null;
			if (this.template) {
				response = await editTemplate({ _id: this.template._id, active, name, options });
			} else {
				response = await addTemplate({ active, name, options });
			}

			if ([200, 201].includes(response.status)) {
				await this.uploadImage(response.data._id);

				if (!isPreview) {
					this.onClose();

					this.openToast({ message: "Template atualizado com sucesso", color: "green" });
				}
			}

			if (!isPreview) this.loading = false;
		},
	},
});
</script>
