<style scoped>
.progress {
	width: 100%;
}
.progress:after {
	position: absolute;
	background: rgba(0, 0, 0, 0.35);
	left: 0;
	width: var(--percentage);
}
.triangleDown {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 14px solid #555;
	margin-bottom: 2px;
	position: relative;
}
.conversionRateBar {
	width: 100%;
	height: 25px;
	background: linear-gradient(to right, red 0%, yellow 50%, green 100%);
	border-radius: 3px;
}
.avatar {
	width: 190px;
	height: 190px;
	border-radius: 50%;
	overflow: hidden;
	margin: 10px;
}
.avatar img {
	width: 214px;
}
</style>

<template>
	<div id="userDetails" v-if="user">
		<div style="display: flex; gap: 10px; flex-direction: column">
			<div style="display: flex; gap: 10px; flex-wrap: wrap; flex-grow: 1">
				<v-card style="width: 400px; flex-grow: 1">
					<v-list two-line style="text-align: left">
						<v-list-item style="padding: 0px; margin: 0px; display: flex; align-items: center">
							<span>
								<v-list-item>
									<v-list-item-icon>
										<v-icon color="primary">mdi-account</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-subtitle>Nome</v-list-item-subtitle>
										<v-list-item-title>{{ user.name }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="user.gender">
									<v-list-item-icon>
										<v-icon color="primary">mdi-gender-male-female</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-subtitle>Género</v-list-item-subtitle>
										<v-list-item-title>
											{{ config.genders.find(g => g.key === user.gender).name }}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="user.birthday">
									<v-list-item-icon>
										<v-icon color="primary">mdi-cake-variant</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-subtitle>Data de Nascimento</v-list-item-subtitle>
										<v-list-item-title>{{ formatDate(user.birthday, "DD-MM-YYYY") }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</span>
							<span>
								<div v-if="user.image" class="avatar" @click="showImageDetail = !showImageDetail">
									<img :src="getFileUrl(user.image)" />
								</div>
							</span>
						</v-list-item>
						<v-divider inset />
						<v-list-item v-if="user.personalEmail">
							<v-list-item-icon>
								<v-icon color="primary">mdi-email</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Email Pessoal</v-list-item-subtitle>
								<v-list-item-title>{{ user.personalEmail }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.phone">
							<v-list-item-icon>
								<v-icon color="primary">mdi-phone</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Telefone</v-list-item-subtitle>
								<v-list-item-title>{{ user.phone }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.phone2">
							<v-list-item-icon>
								<v-icon color="primary">mdi-phone</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Telefone 2</v-list-item-subtitle>
								<v-list-item-title>{{ user.phone2 }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider inset />
						<v-list-item v-if="user.qualifications">
							<v-list-item-icon>
								<v-icon color="primary">mdi-school</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Habilitações literárias</v-list-item-subtitle>
								<v-list-item-title>
									{{ config.qualifications.find(q => q.key === user.qualifications).name }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.country">
							<v-list-item-icon>
								<v-icon tag="span">{{ countries[user.country].emoji }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>País</v-list-item-subtitle>
								<v-list-item-title>{{ countries[user.country].name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider inset v-if="user.cc || user.residenceTitle" />
						<v-list-item v-if="user.residenceTitle">
							<v-list-item-icon>
								<v-icon color="primary">mdi-home</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Título de Residência</v-list-item-subtitle>
								<v-list-item-title>Sim</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.residenceTitle && user.residenceExpirationDate">
							<v-list-item-icon>
								<v-icon color="primary">mdi-calendar-check</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Data de Validade</v-list-item-subtitle>
								<v-list-item-title>
									{{ formatDate(user.residenceExpirationDate, "DD-MM-YYYY") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="!user.residenceTitle && user.cc">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-box</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Nº de Cartão de Cidadão</v-list-item-subtitle>
								<v-list-item-title>{{ user.cc }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="!user.residenceTitle && user.expirationDate">
							<v-list-item-icon>
								<v-icon color="primary">mdi-calendar-check</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Data de Validade do CC</v-list-item-subtitle>
								<v-list-item-title>{{ formatDate(user.expirationDate, "DD-MM-YYYY") }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.nif">
							<v-list-item-icon>
								<v-icon color="primary">mdi-numeric</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>NIF</v-list-item-subtitle>
								<v-list-item-title>{{ user.nif }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.niss">
							<v-list-item-icon>
								<v-icon color="primary">mdi-numeric</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>NISS</v-list-item-subtitle>
								<v-list-item-title>{{ user.niss }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.address">
							<v-list-item-icon>
								<v-icon color="primary">mdi-google-maps</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>{{ user.address }}</v-list-item-subtitle>
								<v-list-item-title>{{ user.postalCode }} {{ user.city }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
				<v-card style="width: 400px; flex-grow: 1">
					<v-list two-line style="text-align: left">
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="primary">mdi-email</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Email</v-list-item-subtitle>
								<v-list-item-title>{{ user.email }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-star</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Cargo</v-list-item-subtitle>
								<v-list-item-title>
									{{
										user.role === "sysAdmin" ? "System Admin" : config.roles.find(r => r.key === user.role).name
									}}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="primary">mdi-store</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Agência</v-list-item-subtitle>
								<v-list-item-title>
									<v-chip small v-for="office in user.offices" :key="office.key" style="margin: 5px 5px 0px 0px">
										{{ allOffices.find(o => o.key === office).name }}
									</v-chip>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.broker">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-key</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Broker</v-list-item-subtitle>
								<v-list-item-title>{{ user.broker.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.team">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-multiple</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Equipa</v-list-item-subtitle>
								<v-list-item-title>{{ config.teams.find(t => t.key === user.team)?.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.manager">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-switch</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Responsável</v-list-item-subtitle>
								<v-list-item-title>{{ user.manager.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.referencedBy">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-network</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Referência</v-list-item-subtitle>
								<v-list-item-title>{{ user.referencedBy.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.recruiter">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-search</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Recrutador</v-list-item-subtitle>
								<v-list-item-title>{{ user.recruiter.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.integration">
							<v-list-item-icon>
								<v-icon color="primary">mdi-account-multiple</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Integrador</v-list-item-subtitle>
								<v-list-item-title>{{ user.integration.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider inset />
						<v-list-item v-if="user.contractType">
							<v-list-item-icon>
								<v-icon color="primary">mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Tipo de Contrato</v-list-item-subtitle>
								<v-list-item-title>
									{{ config.contractTypes.find(t => t.key === user.contractType).name }}
								</v-list-item-title>
								<v-list-item-subtitle>
									{{ user.fullTime ? "Tempo Inteiro" : "Tempo Parcial" }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.startDate">
							<v-list-item-icon>
								<v-icon color="primary">mdi-calendar-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Início de Contrato</v-list-item-subtitle>
								<v-list-item-title>{{ formatDate(user.startDate, "DD-MM-YYYY") }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.endDate">
							<v-list-item-icon>
								<v-icon color="primary">mdi-calendar-remove</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Fim de Contrato</v-list-item-subtitle>
								<v-list-item-title>{{ formatDate(user.endDate, "DD-MM-YYYY") }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-if="
								features.editCommissions && ['consultant', 'teamLeader'].includes(user.role) && user.commission
							"
						>
							<v-list-item-icon>
								<v-icon color="primary">mdi-percent</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Comissão</v-list-item-subtitle>
								<v-list-item-title>{{ user.commission }} %</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-if="
								features.editCommissions && ['consultant', 'teamLeader'].includes(user.role) && user.bankCommission
							"
						>
							<v-list-item-icon>
								<v-icon color="primary">mdi-bank</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Comissão Bancária</v-list-item-subtitle>
								<v-list-item-title>{{ user.bankCommission }} %</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-if="
								features.editResponsableCommissions &&
								['consultant', 'teamLeader'].includes(user.role) &&
								user.commercialDirectorCommission
							"
						>
							<v-list-item-icon>
								<v-icon color="primary">mdi-ticket-percent</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Comissão do Diretor Comercial</v-list-item-subtitle>
								<v-list-item-title>{{ user.commercialDirectorCommission }} %</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-if="
								features.editResponsableCommissions &&
								['consultant', 'teamLeader'].includes(user.role) &&
								user.brokerCommission
							"
						>
							<v-list-item-icon>
								<v-icon color="primary">mdi-ticket-percent</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Comissão do Broker</v-list-item-subtitle>
								<v-list-item-title>{{ user.brokerCommission }} %</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-if="user.observations">
							<v-list-item-icon>
								<v-icon color="primary">mdi-format-align-left</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-subtitle>Observações</v-list-item-subtitle>
								<v-list-item-title class="text-wrap">{{ user.observations }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<div v-if="user.socialMedia">
							<v-divider v-if="Object.values(user.socialMedia).find(s => s)" inset />
							<v-list-item v-if="user.socialMedia.facebook">
								<v-list-item-icon>
									<v-icon color="primary">mdi-facebook</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-subtitle>Facebook</v-list-item-subtitle>
									<v-list-item-title>
										<a :href="`https://facebook.com/${user.socialMedia.facebook}`" target="_blank">
											facebook.com/{{ user.socialMedia.facebook }}
										</a>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="user.socialMedia.instagram">
								<v-list-item-icon>
									<v-icon color="primary">mdi-instagram</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-subtitle>Instagram</v-list-item-subtitle>
									<v-list-item-title>
										<a :href="`https://instagram.com/${user.socialMedia.instagram}`" target="_blank">
											instagram.com/{{ user.socialMedia.instagram }}
										</a>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="user.socialMedia.twitter">
								<v-list-item-icon>
									<v-icon color="primary">mdi-twitter</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-subtitle>Twitter</v-list-item-subtitle>
									<v-list-item-title>
										<a :href="`https://twitter.com/${user.socialMedia.twitter}`" target="_blank">
											twitter.com/{{ user.socialMedia.twitter }}
										</a>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="user.socialMedia.linkedin">
								<v-list-item-icon>
									<v-icon color="primary">mdi-linkedin</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-subtitle>LinkedIn</v-list-item-subtitle>
									<v-list-item-title>
										<a :href="`https://linkedin.com/in/${user.socialMedia.twitter}`" target="_blank">
											linkedin.com/in/{{ user.socialMedia.linkedin }}
										</a>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</div>
					</v-list>
				</v-card>
				<Files
					:files="user.files && user.files.filter(f => !f.type)"
					group="users"
					:id="user._id"
					:patchFunction="patchUser"
					:onSubmit="onClose"
					style="width: 400px; flex-grow: 1"
				/>
			</div>
			<div style="display: flex; flex-wrap: wrap; gap: 10px">
				<Comments
					group="users"
					:id="user._id"
					:inline="true"
					style="width: 400px; flex-grow: 1"
					:types="[
						{ name: 'Diretor Comercial', value: 'commercialDirector' },
						{ name: 'Broker', value: 'broker' },
					]"
				/>
				<v-card style="width: 400px; flex-grow: 1">
					<v-card-title>Tarefas</v-card-title>
					<Tasks />
				</v-card>
			</div>
			<div>
				<v-card v-if="user.role === 'recruiter'" max-width="375" class="mx-auto">
					<v-list two-line style="text-align: left">
						<v-subheader>OBJETIVOS</v-subheader>
						<v-list-item
							v-for="goal in user.goals"
							:key="goal._id"
							class="progress"
							:style="`--percentage: ${ratioCompleted(goal) * 100}%`"
						>
							<v-list-item-icon style="z-index: 1">
								<v-icon color="primary">mdi-calendar</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="z-index: 1">
								<v-list-item-title>
									{{ `${goal.number} ${config.goalTypes.find(t => t.key === goal.type).name}` }}
								</v-list-item-title>
								<v-list-item-subtitle>
									{{ config.goalFrequencies.find(t => t.key === goal.frequency).name }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-btn
						v-if="user.goals.length < 25"
						fab
						x-small
						color="primary"
						@click="openGoalsForm"
						style="top: 16px; right: 10px; position: absolute"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-card>
				<br />
				<v-card
					v-if="user.role === 'recruiter'"
					max-width="375"
					class="mx-auto"
					style="background-color: transparent"
					elevation="0"
				>
					<h5 align="left">Taxa de Conversão</h5>
					<v-chip
						small
						:style="`left:${(conversionRate() > 10 ? 10 : conversionRate()) * 10 - 50}%; position: relative`"
					>
						{{ conversionRate() }}%
					</v-chip>
					<div class="triangleDown" :style="`left:${(conversionRate() > 10 ? 10 : conversionRate()) * 10 - 2}%`" />
					<div class="conversionRateBar" />
					<div>
						<span style="font-size: 0.75em; float: left">0%</span>
						<span style="font-size: 0.75em; float: right">10%</span>
					</div>
				</v-card>
			</div>
		</div>
		<v-dialog v-model="showImageDetail" max-width="1000px">
			<img :src="getFileUrl(user.image)" />
		</v-dialog>
		<v-dialog v-if="user.role === 'recruiter'" v-model="showGoalsForm" max-width="1000px">
			<v-sheet outlined rounded>
				<v-row justify="center" no-gutters>
					<v-col style="margin: 40px; max-width: 500px">
						<v-list two-line style="text-align: left">
							<v-list-item v-for="goal in user.goals.concat(goals)" :key="goal._id">
								<v-list-item-icon>
									<v-icon color="primary">mdi-new-box</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>
										{{ `${goal.number} ${config.goalTypes.find(t => t.key === goal.type).name}` }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ config.goalFrequencies.find(t => t.key === goal.frequency).name }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-form
							ref="form"
							@submit.prevent="handleAddGoals"
							style="display: flex; flex-direction: row; gap: 10px"
						>
							<v-text-field outlined v-model="number" label="Número" type="number" :rules="[required]" />
							<v-select
								outlined
								v-model="type"
								:items="config.goalTypes"
								item-text="name"
								item-value="key"
								label="Tipo"
								:rules="[required]"
							/>
							<v-select
								outlined
								v-model="frequency"
								:items="config.goalFrequencies"
								item-text="name"
								item-value="key"
								label="Frequência"
								:rules="[required, alreadyExists]"
							/>
							<v-btn fab small color="primary" @click="addGoal" style="top: 8px">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-form>
						<br />
						<v-btn color="primary" @click="handleAddGoals">Guardar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import { countries } from "countries-list";

import Files from "./Files";
import Comments from "./Comments";
import Tasks from "./Tasks";

import { patchUser, addGoals } from "../api/users";

import { formatDate, formatAvatarName, getFileUrl, getFileName } from "../utils/utils";

export default Vue.extend({
	name: "UserDetails",
	props: ["user", "onClose"],
	components: { Files, Comments, Tasks },
	created() {
		this.countries = countries;
	},
	computed: {
		...mapState({
			currentUser: "user",
			company: "company",
			token: "token",
			config: "config",
			allOffices: "allOffices",
			features: "features",
		}),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		return {
			showImageDetail: false,
			showGoalsForm: false,

			required: value => !!value || "Obrigatório",
			alreadyExists: value =>
				!this.user.goals.concat(this.goals).find(g => g.type === this.type && g.frequency === value) ||
				"Já existe",

			countries: [],

			goals: [],
			frequencies: [],
			number: null,
			type: null,
			frequency: null,
		};
	},
	methods: {
		formatDate,
		formatAvatarName,
		getFileUrl,
		getFileName,
		patchUser,
		ratioCompleted(goal) {
			const day = dayjs().get("date");
			const week = dayjs().get("week") - 1;
			const month = dayjs().get("month") + 1;
			const quarter = month <= 3 ? 1 : month <= 6 ? 2 : month <= 9 ? 3 : 4;
			const year = dayjs().get("year");

			const goalStats = this.user.stats[goal.type];

			let filteredStats = null;
			switch (goal.frequency) {
				case "daily":
					filteredStats = goalStats.filter(s => s._id.day === day && s._id.month === month && s._id.year === year);
					break;
				case "weekly":
					filteredStats = goalStats.filter(s => s._id.week === week && s._id.year === year);
					break;
				case "monthly":
					filteredStats = goalStats.filter(s => s._id.month === month && s._id.year === year);
					break;
				case "quarterly":
					filteredStats = goalStats.filter(s => s._id.quarter === quarter && s._id.year === year);
					break;
				case "yearly":
					filteredStats = goalStats.filter(s => s._id.year === year);
					break;
			}

			const stat = filteredStats.reduce((acc, s) => acc + s.total, 0);

			return (stat || 0) / goal.number;
		},
		conversionRate() {
			if (this.user.stats) {
				const recruitedStat = this.user.stats["recruited"].reduce((acc, s) => acc + s.total, 0);
				const recruiterAcceptedStat = this.user.stats["recruiterAccepted"].reduce((acc, s) => acc + s.total, 0);

				const conversionRate = ((recruitedStat / recruiterAcceptedStat) * 100).toFixed();

				return !isNaN(conversionRate) ? conversionRate : 0;
			}

			return 0;
		},
		openGoalsForm() {
			this.showGoalsForm = true;
		},
		addGoal() {
			if (!this.$refs.form.validate()) return;

			this.goals.push({ number: this.number, type: this.type, frequency: this.frequency });
		},
		async handleAddGoals() {
			if (!this.goals.length) return;

			const response = await addGoals(this.user._id, this.goals);

			if (response.status === 200) {
				this.showGoalsForm = false;
				this.goals = [];

				this.onClose();
			}
		},
	},
});
</script>
