<template>
	<v-row v-if="userInfo" style="max-width: 1500px" class="mx-auto">
		<v-col style="margin-right: 5px">
			<h1>Perfil</h1>
			<br />
			<UserDetails :user="userInfo" />
		</v-col>
		<v-col style="margin-top: 70px">
			<v-card min-width="250" max-width="400" class="mx-auto pa-5">
				<h3>Preferências</h3>
				<br />
				<v-checkbox outlined v-model="darkModeSetting" label="Dark Mode" @change="handlePatchUser" />
				<v-checkbox outlined v-model="horizontalMenuSetting" label="Menu Horizontal" @change="handlePatchUser" />
			</v-card>
			<br />
			<v-card min-width="250" max-width="400" class="mx-auto pa-5">
				<h3>Mudar Password</h3>
				<br />
				<v-form ref="form" @submit.prevent="handleChangePassword">
					<v-text-field
						outlined
						v-model="oldPassword"
						label="Password Antiga"
						:rules="[oldPasswordRequired]"
						:append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showOldPassword ? 'text' : 'password'"
						@click:append="showOldPassword = !showOldPassword"
					/>
					<v-text-field
						outlined
						v-model="newPassword"
						label="Password Nova"
						:rules="[newPasswordRequired, samePassword, differentThanOld]"
						:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showNewPassword ? 'text' : 'password'"
						@click:append="showNewPassword = !showNewPassword"
					/>
					<v-text-field
						outlined
						v-model="newPasswordRepeat"
						label="Repetir Password Nova"
						:rules="[samePassword, differentThanOld]"
						:append-icon="showNewPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showNewPasswordRepeat ? 'text' : 'password'"
						@click:append="showNewPasswordRepeat = !showNewPasswordRepeat"
					/>
					<v-btn type="submit" color="primary">Guardar</v-btn>
				</v-form>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
// TODO: change to ChangePassword component
import UserDetails from "../components/UserDetails";

import { getUser, patchUser } from "../api/users";
import { changePassword } from "../api/auth";

export default Vue.extend({
	name: "UserProfile",
	components: {
		UserDetails,
	},
	created() {
		this.handleGetUser();
	},
	watch: {
		oldPassword: "validatePasswords",
		newPassword: "validatePasswords",
		newPasswordRepeat: "validatePasswords",
		darkMode: function (newVal) {
			this.darkModeSetting = newVal;
		},
		horizontalMenu: function (newVal) {
			this.horizontalMenuSetting = newVal;
		},
	},
	computed: mapState(["user", "darkMode", "horizontalMenu"]),
	data() {
		return {
			userInfo: null,

			darkModeSetting: true,
			horizontalMenuSetting: false,

			oldPassword: "",
			newPassword: "",
			newPasswordRepeat: "",

			showOldPassword: false,
			showNewPassword: false,
			showNewPasswordRepeat: false,
			oldPasswordRequired: () =>
				this.oldPassword !== "" || (this.newPassword !== "" && this.oldPassword !== "") || "Obrigatório",
			newPasswordRequired: () =>
				this.newPassword !== "" || (this.oldPassword !== "" && this.newPassword !== "") || "Obrigatório",
			samePassword: () => this.newPassword === this.newPasswordRepeat || "Passwords têm de ser iguais",
			differentThanOld: () =>
				this.newPassword !== this.oldPassword || "Nova password têm de ser diferente da antiga",
		};
	},
	methods: {
		...mapMutations(["login", "openToast", "setDarkMode", "setHorizontalMenu"]),
		async handleGetUser() {
			const response = await getUser(this.user._id);

			if (response.status === 200) {
				this.userInfo = response.data;

				this.darkModeSetting = this.userInfo.darkMode;
				this.horizontalMenuSetting = this.userInfo.horizontalMenu;
			}
		},
		async handlePatchUser() {
			if (this.darkModeSetting !== this.darkMode) {
				this.setDarkMode(this.darkModeSetting);
				this.$vuetify.theme.dark = this.darkModeSetting;
			}

			if (this.horizontalMenuSetting !== this.horizontalMenu) {
				this.setHorizontalMenu(this.horizontalMenuSetting);
			}

			const response = await patchUser({
				_id: this.user._id,
				darkMode: this.darkModeSetting,
				horizontalMenu: this.horizontalMenuSetting,
			});

			if (response.status === 200) {
				this.userInfo = response.data;
			}
		},
		validatePasswords() {
			this.$refs.form.validate();
		},
		isFormValid() {
			if (!this.$refs.form.validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			return true;
		},
		async handleChangePassword() {
			if (!this.isFormValid()) return;

			const response = await changePassword({ oldPassword: this.oldPassword, newPassword: this.newPassword });

			if (response.status === 200) {
				this.login({ user: { ...this.user, needsPasswordChange: false } });

				this.openToast({ message: "Password mudada com sucesso", color: "green" });

				this.$router.push("/");
			} else {
				this.openToast({ message: "Credenciais Inválidas", color: "red" });
			}
		},
	},
});
</script>
