<template>
	<div style="display: flex; justify-content: center; align-items: center; height: 100%">
		<v-card style="padding: 20px; max-width: 400px">
			<v-form ref="form" @submit.prevent="handleSubmit">
				<div style="display: flex; gap: 10px">
					<v-select
						outlined
						item-value="_id"
						item-text="name"
						label="Template"
						v-model="template"
						:items="templates"
					/>
					<v-btn icon v-if="template" color="primary" @click="openForm(template)" style="top: 10px">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon color="primary" @click="openForm()" style="top: 10px">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<v-autocomplete
					outlined
					item-value="_id"
					item-text="name"
					label="Consultores"
					v-model="consultants"
					:items="users"
					small-chips
					multiple
				/>
				<v-progress-circular v-if="loading" indeterminate color="primary" />
				<v-btn v-else :disabled="!template || !consultants.length || loading" color="primary" type="submit">
					Gerar
				</v-btn>
			</v-form>
		</v-card>
		<v-dialog v-model="showForm">
			<TemplateForm :template="selectedTemplate" :onClose="closeForm" />
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";

import TemplateForm from "../components/TemplateForm";

import { getUsers } from "../api/users";
import { getTemplates, generateConsultantImages } from "../api/templates";

export default Vue.extend({
	name: "ImageGenerator",
	components: { TemplateForm },
	async created() {
		this.handleGetUsers();
		this.handleGetTemplates();
	},
	data() {
		return {
			loading: false,

			showForm: false,
			selectedTemplate: null,

			templates: [],
			users: [],

			template: "",
			consultants: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		async handleGetTemplates() {
			const response = await getTemplates();

			if (response.status === 200) {
				this.templates = response.data;
			}
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		async handleSubmit() {
			this.loading = true;

			const { template, consultants } = this;

			const response = await generateConsultantImages({ template, consultants });

			if (response.status === 200) {
				this.openToast({ message: "Imagens geradas com sucesso", color: "green" });

				window.open(`${process.env.VUE_APP_API_URL}/api/files/download/images.zip`, "_blank");
			}

			this.loading = false;
		},
		openForm(item) {
			if (item) {
				this.selectedTemplate = this.templates.find(t => t._id === item);
			} else {
				this.selectedTemplate = null;
			}

			this.showForm = true;
		},
		closeForm() {
			this.handleGetTemplates();

			this.showForm = false;
		},
	},
});
</script>
